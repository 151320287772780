import React from 'react'
import '../css/prepararViaje.css'
import { Outlet, Link } from "react-router-dom";
import { Col, Row,  } from 'antd';

import antes from '../assets/images/informacion/antes.jpg';
import durante from '../assets/images/informacion/durante.png';
import despues from '../assets/images/despues.webp';

import reservar from '../assets/images/cta/reservacion.svg'
import viajeGrupo from '../assets/images/cta/viajegrupo.svg'
import auto from '../assets/images/cta/viajaenauto.svg'

import contactanos from '../assets/images/contactanos.webp';
import preguntasfrecuentes from '../assets/images/FAQS-img.webp';
import terminoscondiciones from '../assets/images/terminoscondiciones.webp';

import Footer from '../components/Footer';
import LogoCarousel from '../components/Carousel';

const logos = [
    './images/esr.png',
    './images/imss.png',
    './images/lrqa.png',
    './images/prime.png',
    './images/distintivo-h.png',
    './images/safe-travel.png',
    './images/esr-inclusion.png',
    './images/punto-limpio.png',
    // Añade más logos aquí...
];


export default function PrepararViaje() {
  return (
    <>

        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Información para tu viaje</h3>
                        <p>Encuentra toda la información que necesitas saber para antes, durante y despúes de tu viaje. </p>
                    </div>                
                </div>
            </div>
        </section>

        <section className='container'>
            
            <div className='box_prepararViaje'>
                <h2>Prepara tu viaje</h2>
                {/*
                <p>Baja Ferries te conecta con increíbles destinos para disfrutar solo, en pareja, con tu familia o amigos. Puedes viajar con tu 
                    auto y explorar todo lo que México tiene para ofrecer.</p>   */}             
  </div>
            <Row>
                <Col span={8}>
                    <div className='content_documentacion'>
                        <Link to="/antes-de-viajar">                                         
                            <div className="cardPrepararViaje">
                                <img src={antes} alt="pichilingue" className='imgDestino' />                        
                                <div className="textPrepararViaje">
                                    <h4>Antes de tu viaje</h4> 
                                    <p>Antes de abordar, asegúrate de revisar los requisitos de viaje, horarios actualizados y servicios disponibles.</p> 
                                </div>
                            </div>
                        </Link> 
                
                    </div>
                </Col>
                <Col span={8}>
                    <div className='content_antesViajar'>
                        <Link to="/durante_tu_viaje"> 
                        
                            <div className="cardPrepararViaje">
                                <img src={durante} alt="mazatlan" className='imgDestino' />                        
                                <div className="textPrepararViaje">
                                    <h4>Durante tu viaje</h4> 
                                    <p>Conoce los servicios y entretenimiento a bordo. Disfruta de las instalaciones disponibles durante el viaje.</p> 
                                </div>
                            </div>

                        </Link>                         
                    </div>
                </Col>
                <Col span={8}>
                    <div className='content_paraViajar'> 
                        <Link to="/al-llegar">  
                            <div className="cardPrepararViaje">
                                <img src={despues} alt="topolobampo" className='imgDestino' />                        
                                <div className="textPrepararViaje">
                                    <h4>A tu llegada</h4> 
                                    <p>Asegúrate de recoger todas tus pertenencias al desembarcar y planifica cómo llegar a tu destino desde el puerto.</p> 
                                </div>
                            </div>
                        </Link>                         
                    </div>
                </Col>               
            </Row>
        </section>
        {
            /*
        <section className='container'>
            <div className='box_prepararViaje'>
                <h2>Servicio al cliente</h2>
                {/*<p>Baja Ferries te conecta con increíbles destinos para disfrutar solo, en pareja, con tu familia o amigos. Puedes viajar con tu auto y explorar todo lo que México tiene para ofrecer.</p
            </div>
            <Row>
                <Col span={8}>
                    <div className='content_documentacion'>
                    <Link to="/preguntas-frecuentes"> 
                        <div className="cardPrepararViaje">
                            <img src={preguntasfrecuentes} alt="pichilingue" className='imgDestino' />                        
                            <div className="textPrepararViaje">
                                <h4>Preguntas frecuentes</h4> 
                                <p>Si necesitas consultar algo y tu pregunta no aparece en la lista, no dudes en ponerte en contacto con nuestro equipo de atención al cliente.</p> 
                            </div>
                        </div>
                    </Link>                         
                    </div>
                </Col>
                <Col span={8}>
                    <div className='content_antesViajar'>
                    <Link to="/contacto"> 
                        <div className="cardPrepararViaje">
                            <img src={contactanos} alt="mazatlan" className='imgDestino' />                        
                            <div className="textPrepararViaje">
                                <h4>Contáctanos</h4> 
                                <p>Conocida como “La Perla del Pacífico” es de los destinos más visitados en México por turistas nacionales e internacionales.</p> 
                            </div>
                        </div>
                    </Link>                        
                    </div>
                </Col>
                <Col span={8}>
                    <div className='content_paraViajar'> 
                        <Link to="/condiciones-de-servicio"> 
                            <div className="cardPrepararViaje">
                                <img src={terminoscondiciones} alt="topolobampo" className='imgDestino' />                        
                                <div className="textPrepararViaje">
                                    <h4>Términos y condiciones</h4> 
                                    <p>Consulta los términos y condiciones de Baja Ferries.</p> 
                                </div>
                            </div>
                        </Link>                        
                    </div>
                </Col>
            </Row>
        </section>
        
           
        <section className='container'>
            <LogoCarousel logos={logos} />
        </section>
         */
        }
         <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={reservar} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>¿Listo para reservar?</h4>
                                </div>
                                <div>
                                    <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>                               
                                
                                <a href="https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch/" target="_blank" rel="noopener noreferrer">Reservar ahora  </a>   
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={viajeGrupo} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Viaja en grupo</h4>
                                </div>
                                <div>
                                    <p>Si viajas en grupo de 20 personas o más, solicita tu cotización y accede a tarifas y servicios especiales.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/viaje-en-grupo">Más información</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={auto} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>¿Llevas auto?</h4>
                                </div>
                                <div>
                                    <p>Aquí encontraras tódo lo que necesitas saber para viajar con tu auto y explorar cualquiera de nuestros destinos con total libertad.'</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                            <Link to="/antes-de-viajar">Más información</Link> 
                                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        

        <Footer />
        <Outlet />
    </>
  )
}
