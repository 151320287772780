import React, { useRef, useState } from 'react';
import heroImg from '../assets/images/hero.jpg'
import '../css/hero.css';
import '../App.css';

import heroVideo from '../assets/video/Hero.mp4';
import audio from '../assets/video/audio.mp3';
import Reserva from './form/Reserva';

export default function Hero() {
    const videoRef = useRef(null);
    const audioRef = useRef(null);

    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [isPlaying, setIsPlaying] = useState(true); // Para manejar la reproducción/pausa
    const [volume, setVolume] = useState(1); // Para manejar el volumen
    /*
    const toggleAudio = () => {
    if (audioRef.current) {
        if (isAudioPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsAudioPlaying(!isAudioPlaying);
    }
    };*/

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
      audioRef.current.pause();
      
    } else {
      videoRef.current.play();
      audioRef.current.play();
      
    }
    setIsPlaying(!isPlaying);
    
  };

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    setVolume(newVolume);
    videoRef.current.volume = newVolume;
    audioRef.current.volume = newVolume;
  };

  const toggleAudio = () => {
    if (isAudioPlaying) {
        audioRef.current.muted = false;
    }else{
        audioRef.current.muted = true;
    }
    setIsAudioPlaying(!isAudioPlaying)
  };

  return (
    <>
  
    <section>
        <div className='hero' >
        <video ref={videoRef} muted loop autoPlay width="750" height="500" volume={volume}>
          <source src={heroVideo} type="video/mp4" />
        </video>
        {/*
        <audio ref={audioRef} src={audio} loop autoPlay />
        <div className='box_controlAudio'>
          <div className='controlAudio'>
          <div onClick={togglePlayPause}>
              {isPlaying ? (
                <i className="fi fi-ss-pause"></i>
              ) : (
                <i className="fi fi-ss-play"></i>
              )}
            </div>
            <div>
              <label>Fourth Of July Celebration Intro</label>
            </div>
            <div>
              <input type="range" min="0" max="1" step="0.01" value={volume} onChange={handleVolumeChange}/>
            </div>
            
            <div onClick={toggleAudio}>
              {isAudioPlaying ? (
                <i className="fi fi-sr-volume"></i>
                
              ) : (
                
                <i className="fi fi-sr-volume-slash"></i>
                
              )}
            </div>
          </div>
        </div>*/}
            <div className='heroImg'>
                <img src={heroImg} alt='baja ferries hero' />
            </div>
            <div className='hero_text'>
                <div className='container '>
                    <div className='content_hero_text'>
                        <div className='box_hero_text'>
                            <label>Uniendo </label>
                            <label>Conectando</label>
                        </div> 
                        <div className='box_hero_text'>
                            <label>Territorios </label>
                            <label style={{display: 'flex', justifyContent:'start'}}>Personas</label>
                        </div>          
                    </div>
                </div>                                              
            </div>
            <div className='boxBooking'>
                <div className='container'>                                      
                        <Reserva />
                       
                                                                                                                                                        
                </div>
                                
            </div>           
        </div>
    </section>
    </>
  )
}



