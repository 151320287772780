import React from 'react'
import '../css/fleetservice.css';
import california from '../assets/images/flota/cali/CALSTAR-cabina01.webp'
import salon from '../assets/images/flota/cali/CALSTAR-salonturista1.webp'
import salonEjecutivo from '../assets/images/flota/cali/Foto_Salon Ejecutivo.jpg'
import restaurante from '../assets/images/flota/cali/CALSTAR-restaurant1.webp'
import bar from '../assets/images/flota/cali/Foto_Bar.jpg'
import doctor from '../assets/images/cirujano.png'
import Footer from './Footer'

export default function Fleetservice_cali() {
  return (
    <>
    
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>California Star</h3>
                    <p>Servicios abordo</p>
                </div>                
            </div>
        </div>
    </section>
    <section className='fleetserices_ship'>   
        <section className='fleetserices'>
            <div className='container'>
                <div className='fleetserices_content'>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img'>
                                <img src={california} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text'>
                                <h2>Cabinas</h2>
                                <p>Nuestros buques cuentan con cabinas que ofrecen un espacio de descanso durante la travesía. Estas cuentan con camas tipo litera, TV, baño completo y tienen una capacidad máxima de cuatro personas. </p>
                                <p>Algunos de nuestros buques ofrecen cabinas donde tu mascota puede viajar contigo. Incluye una cama y un sobre de alimento. Tu mascota debe pertenecer dentro durante la travesía. </p>
                                <div className='fleetserices_btn'>
                                    <button>Reservar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img'>
                                <img src={salon} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text'>
                                <h2>Área de salón</h2>
                                <p>Al comprar tu boleto, incluye un asiento en el área de salón. Ésta cuenta con aire acondicionado y TV con entretenimiento.  </p>
                                
                                <div className='fleetserices_btn'>
                                    <button>Reservar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img'>
                                <img src={salonEjecutivo} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text'>
                                <h2>Salón ejecutivo</h2>
                               
                                <p>Clase ejecutiva: Si deseas viajar en un asiento más amplio y con más espacio, en la ruta de Topolobampo ofrecemos la opción de viajar en el salón de clase ejecutiva por un costo adicional, además esta área incluye un coffee break </p>
                                <div className='fleetserices_btn'>
                                    <button>Reservar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img'>
                                <img src={restaurante} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text'>
                                <h2>Restaurante</h2>
                                <p>Puedes visitar nuestro restaurante o bar y consumir una variedad de deliciosos alimentos, snacks y bebidas, también puedes ir a la cubierta a disfrutar de un hermoso atardecer</p>
                                <p>El horario para el consumo del alimento en cortesía es durante el embarque, sin embargo, este se encuentra sujeto a cambios </p>
                                <p>La venta de alimentos y snacks en restaurante y en el bar se encuentra abierta en horarios estipulados dentro del buque. Es importante mencionar que el pago a bordo es solo en efectivo</p>
                                <div className='fleetserices_btn'>
                                    <button>Reservar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img'>
                                <img src={bar} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text'>
                                <h2>Bar</h2>
                                <p>Puedes visitar nuestro restaurante o bar y consumir una variedad de deliciosos alimentos, snacks y bebidas, también puedes ir a la cubierta a desfrutar de un hermoso atardecer</p>
                                <p>El horario para el consumo del alimento en cortesía es durante el embarque, sin embargo, este se encuentra sujeto a cambios </p>
                                <p>La venta de alimentos y snacks en restaurante y en el bar se encuentra abierta en horarios estipulados dentro del buque, es importante saber que el pago a bordo es solo en efectivo</p>
                                <div className='fleetserices_btn'>
                                    <button>Reservar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='fleetserices_bottom entretenimiento'>
            <div className='container'>
                <div className='fleetserices_content'>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img'>
                                <img src={california} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text'>
                                <h2>Entretenimiento a bordo</h2>
                                <p>En el buque California Star contamos con un grupo en vivo que ameniza la travesía, además de televisión con películas en zonas comunes como restaurante, bar y salón. </p>
                                <p>El buque Mexico Star cuenta con un área de entretenimiento con un futbolito para que te diviertas jugando durante de la travesía, televisión por cable en las zonas comunes y binoculares en la cubierta </p>

                                <div className='fleetserices_btn'>
                                    <button>Reservar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        </section>
        <section className='fleetserices_medico'>
            <div className='container'>
                <div className='fleetserices_content medico'>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img_medico'>
                                <img src={doctor} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text_medico'>
                                <h2>Medico a bordo</h2>
                                <p>Nuestros buques cuentan con un médico a bordo que se encuentra disponible para consultas o situaciones de emergencia</p>                                
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        </section>
    </section>
    <Footer/>
    </>
  )
}
