import React from 'react'
import '../../css/promocion.css'
import promo1 from '../../assets/images/promo1.png'
import bfIso from '../../assets/images/bf-iso-blanco.png'
import promo2 from '../../assets/images/promociones/banner-mascota.webp'
import lateralPromo1 from '../../assets/images/promociones/transportamas.webp'
import lateralPromo2 from '../../assets/images/promociones/inapam.jpg'
import Temporizador from '../Temporizador'
import { Outlet, Link } from "react-router-dom";
import { Col, Row,  } from 'antd';
import whatsapp from '../../assets/images/chat.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/mapa.svg'
import Footer from '../../components/Footer'

export default function Promo2() {
    const targetDate = new Date('2024-08-18T23:59:59').getTime();

  return (
   <>

    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Promociones para viajar</h3>
                    <p>¡Llévalos de viaje con 50% de descuento o menos!</p>
                </div>                
            </div>
        </div>
    </section>  
    
    <section className='container'>
        <div className='banner-header-promo'>
            <img src={promo2} alt='buen-fin-bajaferries' />
        </div>
    </section>
    
    <section className='sectionPromo_content'>
        <div className='container'>
            <div className='box_promo'>
                <div className='content_text_promo'>
                    <div className='content_promo'>
                        <p>Este Día Mundial del Perro, disfruta de una nueva experiencia junto a tus mejores amigos de cuatro patas. Aprovecha nuestra promoción y llévalos de viaje con un increíble 50% de descuento en todas nuestras rutas.</p>
                        <p>Válido para comprar y viajar del 15 al 31 de julio.</p>
                        
                        <div className='content_promo_btn'>                                                
                            <a className='cta_promo' href="tel:8003377437" rel="noopener noreferrer"><span >Llamar ahora</span></a> 
                            <a target="_blank" className='cta_promo' rel='noopener noreferrer' href='https://wa.me/5218003377437?text=Me%20interesa%20la%20promo%20mascota'>Contactar por WhatsApp</a>
                        </div>   
                    </div>
                    <div className='content_promo condiciones-promo'>
                                            
                    </div>
                    <div className='content_promo condiciones-promo'>
                    {/*<Link to="/condiciones-de-servicio">Consulta términos y condiciones</Link> */}
                    <h4>Términos y condiciones</h4>
                    <ul>
                        <li>• Máximo 2 mascotas por reservación.</li>               
                        <li>• No es acumulable con otras promociones.</li>                        
                    </ul>
                    </div>
                </div>
                <div className='content_contador_promo'>

                    <div className='content_promo'>
                       {/*} <div className='content-isotipo'>
                            <img src={bfIso} alt='bf-isotipo'/>
                        </div>
                        <div>
                            <p>No dejes pasar estas vacaciones de verano y viaja con descuento, comodidad y el respaldo de 21 años de experiencia transformando distancias en oportunidades. </p>
                        </div>
                        <div>
                            <Temporizador targetDate={targetDate} />
                        </div>*/}
                        <h4>Otras promociones</h4>
                        <div className='lateral-promo'>
                            <Link to="/transportando-carga">
                                <img src={lateralPromo1} alt='buen-fin-bajaferries' />
                            </Link> 
                        </div>
                        <div className='lateral-promo'>
                            <Link to="/promo-adulto-mayor">
                                <img src={lateralPromo2} alt='buen-fin-bajaferries' />
                            </Link> 
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>        
    </section>
    <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        {/*<a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>       */}
                            <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>  
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Rutas y Horarios</h4>
                            </div>
                            <div>
                                <p>Explora nuestras rutas y encuentra el horario que mejor se ajuste a tus planes de viaje.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/rutas-horario">Ir ahora</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Tienes dudas?</h4>
                            </div>
                            <div>
                                <p>Visita nuestra seeción de preguntas frecuentes para más información sobre nuestros servicios.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <Link to="/preguntas-frecuentes">Más información</Link>                    
                        </div>
                    </div>
                </div>
            </div>

        </div>
   </section>
        <Outlet />

    <Footer />
   
   </>
  )
}
