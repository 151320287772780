import React, {useEffect, useState} from 'react'

export default function NewsletterBox() {
    const [alert, setAlert] = useState(false);
    const [task, setTask] = useState({
        email:'',
        fecha_alta: '',
        fecha_baja: null,
    })

    useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0]; // Formato: YYYY-MM-DD
        setTask((prevTask) => ({ ...prevTask, fecha_alta: currentDate }));
    }, []);


    const handleChange = (e) => {

        setTask({...task, [e.target.name]: e.target.value});
        console.log("task", task);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("task", task);

        try {
            //await axios.post('http://localhost:3004/newsletter', {
            //await fetch('http://localhost:3001/newsletter',{
            await fetch('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/newsletter',{
                method:'POST',
                body: JSON.stringify(task),
                headers: {'Content-Type': 'application/json'},
            });
    
            // Limpiar campos después de enviar el correo
            setTask({
                email: '',
                fecha_alta: new Date().toISOString().split('T')[0],
                fecha_baja: '',
            });
            console.log('Formulario enviado con éxito');
            setAlert(true);
            
        } catch (error) {
            console.error('Error al enviar el correo:', error);
            
        }
    }

  return (
    <div className='noticias_content_newsletter'>
    <div className='noticias_box_newsletter'>
        
        <div className='noticias_box_newsletter'>
            <h2>Suscríbete a nuestro newsletter</h2>
            <p>Ingresa tu correo electrónico para recibir noticias, avisos y promociones especiales. </p>
            {alert &&(
                
            <div className='alertMessage'>
                <label>¡Te has suscrito correctamente! </label>                
            </div>
            )}
           
            <form onSubmit={handleSubmit} className='noticias_input_newsletter'>                           
                <input type='email' name='email' value={task.email} onChange={handleChange} placeholder='Ingresa tu correo'/>
                <button type='submit'  disabled={!task.email}> Suscribirme </button>
            </form>
        </div>
    </div>

</div>
  )
}
